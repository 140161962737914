import React from 'react';
import { Container } from 'react-bootstrap';
import { useDocTitle } from '../customHooks';
import Hero from './Hero'
import SideBySide from './SideBySide';

const Referenzen = props => {

    useDocTitle('DLM Factory - Referenzen')
    return (
        <>
            <Hero text="Referenzen" image="./referenzen.jpg"></Hero>
            <Container className="py-5">
                <SideBySide img="./ad.jpg" title="Die ambulanten Dienste" orientation="left" href="http://www.die-ambulanten-dienste.de">Redaktionelle Betreuung und Pflege des Auftritts von Augusta Ambulante Dienste gGmbH, des größten Pflegedienstes im Bereich Bochum/Hattingen.</SideBySide>
                <SideBySide img="./mir.jpg" title="Menschen im Revier" orientation="right" href="http://www.menschen-im-revier.com">Gestaltung des kompakten Internetauftritts für die gemeinnützige Gesellschaft, die Hilfe, Unterstützung und Betreuung für kranke, alte und hilfebedürftige Menschen anbietet.</SideBySide>
                <SideBySide img="./lod.jpg" title="Leben ohne Dich e.V." orientation="left" href="https://www.leben-ohne-dich.de">Layout, Implementierung (2001), Hosting und laufender Ausbau der WebSite der gemeinnützigen Organisation Leben ohne Dich e. V. - Selbsthilfe für Familien mit verstorbenen Kindern.<br /><br />Übersicht über alle Hilfsangebote des Vereins und bildliches Gedenken an mehr als 1.000 verstorbene Kinder.</SideBySide>
                <SideBySide img="./wt.jpg" title="Witten Transparent" orientation="right" href="http://www.witten-transparent.de">WebSite des lokalen Gesundheitsmagazins (Bochum, Witten) mit Layout und Hosting seit 2010, inklusive Downloadmöglichkeit des kostenlosen Magazins.</SideBySide>
                <SideBySide img="./patrick.jpg" title="Patrick Skrypczak" orientation="left" href="https://www.leben-ohne-dich.de/patrick/freie_arbeiten.html">Das Urbane und die Gesellschaft sind Patrick Skrypczaks Themen und Inspirationen. Er studierte Fotografie an der Fachhochschule Dortmund und ist seit 2007 als freiberuflicher Fotograf tätig. Seine Arbeiten werden international veröffentlicht und ausgestellt.</SideBySide>
            </Container>
        </>
    )
}

export default Referenzen;

