import React, { useState } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';

const Navigation = () => {

    const [expanded, setExpanded] = useState(false);

    return (
        <Navbar variant='light' bg="light" expand="md"  expanded={expanded}>
            <Container>
                <Navbar.Brand as={NavLink} to="/" onClick={() => setExpanded(false)}>
                    <img
                        src="./logo.svg"
                        height="50"
                        alt="dlm logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")}/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link exact as={NavLink} to='/' onClick={() => setExpanded(false)}>HOME</Nav.Link>
                        <Nav.Link as={NavLink} to='/leistungen' onClick={() => setExpanded(false)}>LEISTUNGEN</Nav.Link>
                        <Nav.Link as={NavLink} to='/referenzen' onClick={() => setExpanded(false)}>REFERENZEN</Nav.Link>
                        <Nav.Link as={NavLink} to='/impressum' onClick={() => setExpanded(false)}>IMPRESSUM</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Navigation;