import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Hero from './Hero'

export default function NoMatch() {
    return (
        <>
            <Hero text="404" image="./404.jpg"></Hero>
            <Container>
                <Row>
                    <Col xs={'auto'} className="mx-auto mt-5">
                        <h1>Die aufgerufene Seite existiert nicht</h1>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
