import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './SideBySide.scss'

const SideBySide = props => {
    return (
        props.orientation == 'right' ?
            <Row id={props.id} className="my-5 mx-0 sbs-container">
                <Col sm={12} md={6} className="sbs-img" style={{ backgroundImage: `url(${props.img})`, }}>
                    {props.href ? <a className="sbs-link" href={props.href} target="_blank"></a> : null}
                </Col>
                <Col sm={12} md={4} className="mx-auto my-4">
                    <h1 className="heading-right tk-teko">{props.title}</h1>
                    <p>
                        {props.children}
                    </p>
                </Col>
            </Row> : <Row id={props.id} className="my-5 mx-0 sbs-container">
                <Col sm={12} md={6} className="order-md-last sbs-img" style={{ backgroundImage: `url(${props.img})` }}>
                    {props.href ? <a className="sbs-link" href={props.href} target="_blank"></a> : null}
                </Col>
                <Col sm={12} md={4} className="mx-auto my-4">
                    <h1 className="heading-left tk-teko">{props.title}</h1>
                    <p>
                        {props.children}
                    </p>
                </Col>
            </Row>
    )
}

export default SideBySide;