import './customBootstrap.scss'
import './logo.svg';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Impressum from "./components/Impressum";
import Home from './components/Home';
import Leistungen from './components/Leistungen';
import Referenzen from './components/Referenzen';
import Navigation from './components/Navigation';
import ScrollToTop from './components/ScrollToTop';
import NoMatch from './components/NoMatch';

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ScrollToTop></ScrollToTop>
      <div className="App">
        <Navigation></Navigation>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/impressum' component={Impressum} />
          <Route path='/leistungen' component={Leistungen} />
          <Route path='/referenzen' component={Referenzen} />
          <Route path='/*' component={NoMatch} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
