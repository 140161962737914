import React from 'react';
import './Hero.scss'

const Hero = props => {
    return (
        <div className="hero tk-teko" style={{backgroundImage: `url('${props.image}')`}}>
            <h1>{props.text}</h1>
            <div className="circle"></div>
        </div>
    )
}

export default Hero;
