import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDocTitle } from '../customHooks'
import SideBySide from './SideBySide'
import Hero from './Hero'

const Leistungen = props => {

    useDocTitle('DLM Factory - About')

    return (
        <>
            <Hero text="Leistungen" image="./leistungen.jpg"></Hero>
            <Container className='py-5'>
                <Row>
                    <Col className="mx-auto text-justify text-center">
                        <p className="preface">Mit unserer langjährigen Erfahrung unterstützen wir Sie dabei, Ihre Ziele zu erreichen.</p>
                    </Col>
                </Row>
                <SideBySide img="./design.jpg" title="Website-Layout">Professionalität und Zielgruppenorientierung sollten Ihren wichtigsten Kommunikationskanal bestimmen. Deshalb konzipieren wir Ihren Auftritt nach Ihren Vorstellungen, damit Sie Ihre Online-Mission erfüllen können. Mit unserer WebSite-Konzeption unterstützen wir Sie dabei.</SideBySide>
                <SideBySide img="./erstellung.jpg" title="Website-Erstellung" orientation="right">Es ist uns wichtig, dass Ihre Internet-Präsenz auf allen Endgeräten optimal lesbar ist. Mit den modernsten Tools zur WebSite-Erstellung wird Ihre Internet-Seite auf Smartphone, Tablet oder PC gleichermaßen optimal dargestellt. Ferner bieten wir einen Rundum-Service zur Pflege Ihrer Inhalte an.</SideBySide>
                <SideBySide img="./books.jpg" title="Textrecherche">Für die redaktionellen Inhalte Ihrer Seiten liefern wir nicht nur Layouts, sondern auch die notwendigen Fakten, die wir bei Bedarf im Netz recherchieren und für Sie aufbereiten. Im Normalfall liefern Sie uns Stichworte zu Ihren Wunschthemen.</SideBySide>
                <SideBySide img="./seo2.jpg" title="SEO = Optimierung" orientation="right">Suchmaschinen werden immer leistungsfähiger. Anspruch an Qualität und Technik der WebSites-Inhalte wird also weiter steigen. Wir erledigen alles rund um die Suchmaschinenoptimierung (search engine optimization = SEO), damit Sie leicht gefunden werden. Dafür verarbeiten wir die Stichworte, die Ihr Angebot ausmachen und Ihre Zielgruppe ansprechen.</SideBySide>
            </Container>
        </>
    )
}

export default Leistungen;
