import React from 'react';
import { useDocTitle } from "../customHooks";
import { Container, Row, Col, Button } from 'react-bootstrap';
import Hero from './Hero';
import { BarChart, BlockquoteLeft, Braces, Envelope, Telephone, VectorPen } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'

const Home = props => {

    useDocTitle('DLM Factory')

    return (
        <>
            <Hero image='./hero-home.jpg' text="Willkommen"></Hero>
            <Container className='py-5'>
                <Row>
                    <Col className="mx-auto text-center">
                        <p className="preface">Der Grund, weshalb sehr viele Leute Gelegenheiten nicht wahrnehmen, ist der:
                            <br />Die Gelegenheiten sehen so aus, als ob sie harte Arbeit und vollen Einsatz forderten.
                            <br /><span style={{
                                fontSize: '14px',
                                fontWeight: 'initial',
                                fontStyle: 'italic'
                            }}>Emil Oesch, schweizerischer Schriftsteller und Verleger (1894 - 1974)</span>
                            <br />
                            <br />
                                Deshalb: Wer die besten Früchte ernten will, muss auf den Baum steigen.
                                <br />Wem die Verbeulten genügen, der schüttelt ihn oder wartet darauf, dass sie herunterfallen.
                            </p>
                    </Col>
                </Row>
                <Row className="my-5">
                    <Col xs={12}>
                        <h1 className="heading-left mb-4">Unsere Leistungen</h1>
                    </Col>
                    <Col xs={6} md={3} className="d-flex flex-column align-items-center mb-4">
                        <VectorPen color="rgba(79, 206, 157, 1)" size={90} className="mb-2" />
                        <h5>Website-Layout</h5>
                    </Col>
                    <Col xs={6} md={3} className="d-flex flex-column align-items-center mb-4">
                        <Braces color="rgba(79, 206, 157, 1)" size={90} className="mb-2" />
                        <h5>Website-Erstellung</h5>
                    </Col>
                    <Col xs={6} md={3} className="d-flex flex-column align-items-center mb-4">
                        <BlockquoteLeft color="rgba(79, 206, 157, 1)" size={90} className="mb-2" />
                        <h5>Textrecherche</h5>
                    </Col>
                    <Col xs={6} md={3} className="d-flex flex-column align-items-center mb-4">
                        <BarChart color="rgba(79, 206, 157, 1)" size={90} className="mb-2" />
                        <h5>SEO-Optimierung</h5>
                    </Col>
                    <Col xs={12} className="d-flex justify-content-around">
                        <Button variant="outline-primary" as={Link} to="/leistungen">Mehr erfahren</Button>
                    </Col>
                </Row>
            </Container>
            <div style={{ background: '#eee', transformStyle: 'preserve-3d' }}>
                <Container className='py-5'>
                    <Row className="">
                        <Col xs={12}>
                            <h1 className="heading-left mb-4">Unsere Referenzen</h1>
                        </Col>
                        <div className="d-flex align-items-center justify-content-around my-4">
                            <Col className="pr-0 ml-auto" xs={3} ><img src="./patrick.jpg" className="img-fluid" /></Col>
                            <Col className="px-0" xs={4} ><img src="./ad.jpg" className="img-fluid" /></Col>
                            <Col className="pl-0 mr-auto" xs={3} ><img src="./mir.jpg" className="img-fluid" /></Col>
                        </div>
                        <Col xs={12} className="d-flex justify-content-around">
                            <Button variant="primary" as={Link} to="/referenzen">Weitere entdecken</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className='py-5'>
                <Row>
                    <Col xs={12}>
                        <h1 className="heading-left mb-4">Kontakt</h1>
                    </Col>
                    <Col xs={12} sm={6} md={5} lg={4} className="d-flex flex-column align-items-center mb-4 ml-auto">
                        <Telephone color="rgba(79, 206, 157, 1)" size={90} className="mb-2" />
                        <h5>0171 4658040</h5>
                    </Col>
                    <Col xs={12} sm={6} md={5} lg={4} className="d-flex flex-column align-items-center mb-4 mr-auto">
                        <Envelope color="rgba(79, 206, 157, 1)" size={90} className="mb-2" />
                        <h5>info@dlm-factory.de</h5>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Home;