import React, { useEffect, useState } from 'react';

export const useDocTitle = title => {
    const [docTitle, setDocTitle] = useState(title)

    useEffect(() => {
        document.title = title
    }, []);

    return [title, setDocTitle];
}